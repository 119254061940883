// eslint-disable-next-line no-redeclare,no-unused-vars
function mailer(param = {}) {
  var row = param.row ? param.row : {};
  if (row.id == 'new') {
    showInfo(info.rowEmpty, 'error');
    return;
  }

  var top = param.top;
  var settings = param.settings ? param.settings : {};
  var object = param.object;
  var objectSub = param.objectSub;
  var key = param.key;
  var mail = {func: 'mail', key: key, message: ''};
  var single = true;
  var amount = 0;
  var ids = [];
  var btnSend = info.title.sendMail;
  var attaches = [];
  var attachLi;
  var messageAdd = '';
  var addressCache = localStorage.getItem('mailerAddressCache')?.split(',');

  if (top && isEmptyObject(row)) {
    single = false;
    ids = arrayMerge([], top.table.selected());
    amount = top.table.selectedAll ? top.table.getAmount() : ids.length;
    btnSend = amount + ' ' + info.title.sendMails;
  }

  var buttons = {};
  buttons[btnSend] = function (evt) {
    popup.send(evt.target);
  };
  buttons[info.title.preview] = function (evt) {
    popup.send(evt.target, true);
  };
  buttons[info.title.saveDraft] = function () {
    popup.save();
  };
  //	if (!single) buttons['Probelauf der '+amount+' E-Mails'] = function(evt){ popup.send(evt.target,true,true); };
  var title = settings.label ? settings.label : info.title.writeMail;
  if (!single) title += ' (' + amount + ')';

  var popup = buildPopup({
    title: title,
    buttons: buttons,
    close: param.close,
    afterClose: param.afterClose,
  });
  buildForm(popup.body, {view: popup, prepare: true, noTimeout: true}, [
    '-E-Mail',
    '-Inhalt',
  ]);
  var left = popup.body.find('[data-site=left]').eq(0);
  var right = popup.body.find('[data-site=right]').eq(0);
  var left2 = popup.body.find('[data-site=left]').eq(1);

  // adopt values
  if (settings.adopt)
    $.each(settings.adopt, function (key, value) {
      if (value && str_contains(value, '__'))
        mail[key] = convertPattern({text: value, row: row});
      else mail[key] = row[value];
    });
  if (mail.message) messageAdd = mail.message;

  // fields
  mail.from = user.mail;
  if (!mail.from && store) mail.from = store.mail;
  var fromLi = buildFormLine(left, {
    key: 'from',
    label: 'Von',
    refKey: 'mail',
    field: 'read',
    field2: 'add',
    reference: 'mailAddress',
    where: {assign: user.id, mail: '!'},
    value: mail.from,
    refValue: 1,
    mandatory: true,
    view: popup,
    save: function (value, element, key, els) {
      mail.from = els[0].text();
    },
  });

  // single mailer
  if (single) {
    var toLi;
    var pm = {
      key: 'to',
      label: 'An',
      field: 'input',
      field2: 'add',
      referenceField2: 'customer',
      refKey: 'mail',
      where: {mail: '!'},
      multiple: true,
      mandatory: true,
      view: popup,
      save: function (value) {
        mail.to = value;
      },
    };
    if (addressCache) pm.values = addressCache;

    // mail from parameter
    if (param.to)
      mail.to = convertPattern({text: param.to, emptyOnMissingVar: true});
    // mail from option
    else if (settings.to)
      mail.to = convertPattern({
        text: settings.to,
        row: row,
        object: object,
        emptyOnMissingVar: true,
      });
    // mail from customer
    else if (settings.reference == 'customer' || settings.reference == 'user') {
      mail.to = getData(
        settings.reference,
        row[key],
        'mail',
        settings.objectSub,
        null,
        null,
        []
      );
      if (isArray(mail.to) && mail.to.length > 1) mail.to = [mail.to[0]];
      if (fields[settings.reference].mail2) {
        var to2 = getData(
          settings.reference,
          row[key],
          'mail2',
          settings.objectSub,
          null,
          null,
          ''
        );
        if (isArray(to2) && to2.length) mail.to.push(to2[0]);
      }
    }

    // mail from field
    else if (checkSettings('email', settings)) mail.to = row[key];
    if (isString(mail.to) && str_contains(mail.to, ','))
      mail.to = mail.to.split(',');
    if (!isArray(mail.to)) mail.to = [mail.to];
    if (!mail.to.length) mail.to.push('');

    pm.value = mail.to;
    toLi = buildFormLine(left, pm);
  }

  // mass mailer
  else
    buildFormLine(left, {
      key: 'to',
      label: 'An',
      field: 'read',
      value: amount + ' ' + top.title,
      view: popup,
    });
  if (settings.cc)
    mail.cc = convertPattern({text: settings.cc, row: row, object: object});
  var ccLi = buildFormLine(left, {
    key: 'cc',
    label: 'CC',
    field: 'input',
    field2: 'add',
    referenceField2: 'customer',
    refKey: 'mail',
    where: {mail: '!'},
    value: mail.cc,
    multi: true,
    multiple: true,
    view: popup,
    save: function (value) {
      mail.cc = value;
    },
  });
  if (settings.bcc)
    mail.bcc = convertPattern({
      text: settings.bcc,
      row: row,
      object: object,
    });
  var bccLi = buildFormLine(left, {
    key: 'bcc',
    label: 'BCC',
    field: 'input',
    field2: 'add',
    referenceField2: 'customer',
    refKey: 'mail',
    where: {mail: '!'},
    value: mail.bcc,
    multi: true,
    multiple: true,
    view: popup,
    save: function (value) {
      mail.bcc = value;
    },
  });
  if (settings.subject)
    mail.subject = convertPattern({
      text: settings.subject,
      row: row,
      object: object,
    });
  var subjectLi = buildFormLine(left, {
    key: 'subject',
    label: 'Betreff',
    field: 'input',
    value: mail.subject,
    mandatory: true,
    view: popup,
    save: function (value) {
      mail.subject = value;
    },
  });

  // attach field
  var pmAttach = {
    label: 'Anhang',
    field: 'read',
    field3: 'add',
    field4: 'upload',
    multi: true,
    reference: 'document',
    view: popup,
  };
  if (settings.attach && isPlainObject(settings.attach))
    pmAttach = Object.assign(pmAttach, settings.attach);
  if (pmAttach.where)
    pmAttach.where = convertWhere(pmAttach.where, {
      object: object,
      row: row,
      simple: true,
      noRef: true,
    });
  if (param.attach) pmAttach.value = param.attach;
  else if (settings.reference == 'document' && settings.multi && row[key])
    pmAttach.value = end(row[key]);
  else if (settings.reference == 'document' && !settings.multi && row[key])
    pmAttach.value = row[key];
  else if (mail.attach) pmAttach.value = Object.assign([], mail.attach);
  attachLi = buildFormLine(right, pmAttach);
  attachLi.patternAttachments = [];
  attaches.push(attachLi);

  // message
  var textLi = buildFormLine(left2, {
    key: 'message',
    field: 'editor',
    full: true,
    mandatory: true,
    value: mail.message,
    focus: true,
    view: popup,
    save: function (value) {
      mail.message = value;
    },
  });

  function fill(pattern) {
    if (!pattern) pattern = {};
    if (pattern.id) mail.pattern = pattern.id;

    if (pattern.subject) {
      mail.subject = pattern.subject;
      subjectLi.element.val(mail.subject);
    }
    if (pattern.text) {
      mail.message = pattern.text;
      if (settings.message) mail.message += settings.message;
      if (str_contains(mail.message, '__') && single)
        mail.message = convertPattern({
          text: mail.message,
          row: row,
          object: object,
        });
      if (messageAdd) mail.message += ' ' + messageAdd;
      requestIdleCallback(function () {
        textLi.element.val(mail.message, true);
      });
    }
    if (pattern.mail) {
      mail.from = pattern.mail;
      if (str_contains(mail.from, '__'))
        mail.from = convertPattern({
          text: mail.from,
          row: row,
          object: object,
          element: fromLi.element,
        });
      fromLi.element.val(mail.from);
    }
    if (pattern.cc) {
      mail.cc = pattern.cc;
      if (str_contains(mail.cc, '__'))
        mail.cc = convertPattern({
          text: mail.cc,
          row: row,
          object: object,
          element: ccLi.element,
        });
      ccLi.element.val(mail.cc);
    }
    if (pattern.bcc) {
      mail.bcc = pattern.bcc;
      if (str_contains(mail.bcc, '__'))
        mail.bcc = convertPattern({
          text: mail.bcc,
          row: row,
          object: object,
          element: bccLi.element,
        });
      bccLi.element.val(mail.bcc);
    }
    if (pattern.to && single) {
      mail.to = pattern.to;
      if (str_contains(mail.to, '__'))
        mail.to = convertPattern({
          text: mail.to,
          row: row,
          object: object,
          element: toLi.element,
          mandatory: true,
        });
      toLi.element.val(mail.to);
    }

    // attachment from config
    if (settings.attach && isString(settings.attach)) {
      var attach = calculate(settings.attach, {}, row, {object: object});
      attachLi.add(attach);
    }

    // attachment docs from pattern
    if (pattern.attach) {
      attachLi.addMulti(pattern.attach);
      attachLi.patternAttachments = pattern.attach;
    }

    // attachment field from pattern
    if (pattern.attachField && param.row && pattern.attachField != key) {
      var attachIds = [];
      if (str_contains(pattern.attachField, ',')) {
        $.each(pattern.attachField.split(','), function (i, k) {
          if (k == key) return;
          var attach2 = calculate(k, {}, row, {object: object});
          if (attach2) attachIds = arrayMerge(attachIds, attach2);
        });
      } else {
        var attach2 = calculate(pattern.attachField, {}, row, {
          object: object,
        });
        if (attach2 && isArray(attach2) && attach2.length > 1) {
          attachIds = arrayMerge(attachIds, attach2);
        } else if (attach2 && isArray(attach2)) {
          attachLi.addMulti(attach2);
          attachLi.patternAttachments = arrayMerge(
            attachLi.patternAttachments,
            attach2
          );
        } else if (attach2) {
          attachLi.add(attach2);
          attachLi.patternAttachments.push(attach2);
        }
      }

      // plenty attachments
      if (attachIds.length) {
        var pmAttach = {
          label: 'Anhang',
          field: 'select',
          multi: true,
          reference: 'document',
          value: attachIds,
          values: attachIds,
          labels: [],
          view: popup,
        };
        $.each(attachIds, function (i, attachId) {
          pmAttach.labels.push(data.document[attachId]['short']);
        });
        var attachLi2 = buildFormLine(right, pmAttach);
        if (attaches.length == 1) attaches.push(attachLi2);
        else attaches[1] = attachLi2;
      }
    }
  }

  var where = {};
  if (settings.mailPattern && isNumeric(settings.mailPattern))
    where['$or'] = [{id: settings.mailPattern}, {object: object}];
  else if (settings.mailPattern)
    where['$or'] = [
      {ident: "'" + settings.mailPattern + "'"},
      {object: object},
    ];
  else where.object = object;

  // get mail patterns
  var pm2 = {
    object: 'mailPattern',
    send: {where: where, refObj: object, refId: row.id, mailer: true},
    callback: function (xhr) {
      var pm = {
        object: 'mailPattern',
        ref: object,
        label: 'Mailvorlage',
        patterns: xhr.ids,
        view: popup,
        callback: function (row) {
          if (attachLi.patternAttachments)
            attachLi.removeMulti(attachLi.patternAttachments);
          if (attaches.length > 1) attaches[1].remove();
          fill(row);
        },
      };

      if (settings.mailPattern && xhr.ids) {
        var i = arraySearchAssoc(
          data.mailPattern,
          'ident',
          settings.mailPattern
        );
        if (i === false) i = xhr.ids[0];
        // fill(data.mailPattern[i]);
        pm.value = i;
      } else fill(xhr.mailPattern);

      if (xhr.ids) {
        var li = addPatternField(right, pm);
        li.prependTo(right);
      }
    },
  };
  if (param.massMailer) pm2.send.withVars = true;
  ajax(pm2);

  // send
  popup.send = function (btn, preview) {
    if (popup.mandatory.length) {
      var empty = [];
      $.each(popup.mandatory, function (i, key) {
        empty.push(popup.find('[key=' + key + '] label').text());
      });
      showInfo(
        'Pflichtfelder nicht ausgefüllt: ' + empty.join(', '),
        'warning'
      );
      return;
    }

    loading(true);
    if (!object) object = 'customer';
    fromLi.element.save();
    if (toLi) toLi.element.save();
    ccLi.element.save();
    bccLi.element.save();
    subjectLi.element.save();
    // mail.from = fromLi.element.val();
    // if (toLi) mail.to = toLi.element.val();
    mail.message = textLi.element.val();
    mail.preview = preview;
    mail.attach = [];
    $.each(attaches, function (i, li) {
      var attach = li.element.val();
      if (!attach) return;
      if (isArray(attach)) mail.attach = arrayMerge(mail.attach, attach);
      else mail.attach.push(attach);
    });
    var pm = {
      object: object,
      objectSub: objectSub,
      view: popup,
      post: true,
      send: mail,
    };
    if (single) pm.id = row.id;
    else {
      var result = top.table.filtered(pm.send);
      if (result === false) {
        showInfo(info.pickedEmpty, 'warning');
        return false;
      }
      // pm.send.ids = ids; if (preview && pm.send.ids.length > 10) pm.send.ids = pm.send.ids.splice(0,10);
      pm.send.noData = true;
      pm.noTimeout = true;
    }
    pm.callback = function (xhr) {
      if (!xhr.warning) {
        popup.close();
        if (xhr.info) showInfo(xhr.info);
        // if (pm.send.ids && top) top.updateData();
        if (single) {
          addressCache = arrayMerge(addressCache, mail.to);
          addressCache = arrayUnique(addressCache);
          localStorage.setItem('mailerAddressCache', addressCache);
        }
      }
    };
    ajax(pm);
  };

  // save as draft
  popup.save = function () {
    var mail = {status: 'draft'};
    mail.to = toLi.element.val();
    mail.from = fromLi.element.val();
    mail.cc = ccLi.element.val();
    mail.bcc = bccLi.element.val();
    mail.subject = subjectLi.element.val();
    mail.message = textLi.element.val();
    mail.attach = [];
    $.each(attaches, function (i, li) {
      var attach = li.element.val();
      if (!attach) return;
      if (isArray(attach)) mail.attach = arrayMerge(mail.attach, attach);
      else mail.attach.push(attach);
    });
    if (!mail.attach.length) mail.attach = '';
    var pm = {object: 'mailStat', objectSub: 'draft', post: true, send: mail};
    if (object == 'mailStat' && row.id && row.status == 'draft') pm.id = row.id;
    ajax(pm);
    popup.close();
  };
}
